import { useReducer } from 'react';

import type {
  IGetAdsRequestFilter,
  IGetAdsRequestRange,
} from 'api/types/searchPageApiTypes';
import type { MakeModelType } from 'features/filters/components/MakeModel/MakeModel.typed';

import { savedSearchApi } from 'api/savedSearches';

import { mapMakeModelFilters } from 'components/SearchPage/hooks/useAds';
import { useLocationContext } from 'features/location/Location.context';
import {
  mapFiltersRequest,
  mapGeoFilter,
  mapGeoFilterRequest,
  mapLegacyAreaFilterValues,
  mapRangesRequest,
} from 'features/filters/Filters.mapper';

import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';
import { formatAuthorizationHeader } from 'helpers/auth';
import { formatSearchTerms } from 'helpers/formatSearchTerms';
import { API_CLIENT_TIMEOUT } from 'utils';

export enum NotificationFrequency {
  DAILY = 'DAILY',
  INSTANT = 'INSTANT',
  OFF = 'OFF',
}

enum ActionType {
  SET_SAVING = 'SET_SAVING',
  SET_SAVED = 'SET_SAVED',
  SET_ERROR = 'SET_ERROR',
}

type SetSaving = {
  type: ActionType.SET_SAVING;
};

type SetSaved = {
  type: ActionType.SET_SAVED;
  payload: boolean;
};

type SetError = {
  type: ActionType.SET_ERROR;
  payload: string;
};

type SavedSearchActions = SetSaving | SetSaved | SetError;

interface SavedSearchState {
  saving: boolean;
  saved: boolean;
  error: string;
}

const initialState = {
  saving: false,
  saved: false,
  error: '',
};

const savedSearchReducer = (
  state: SavedSearchState = initialState,
  action: SavedSearchActions,
) => {
  switch (action.type) {
    case ActionType.SET_SAVING:
      return { ...state, saving: true, error: '', saved: false };
    case ActionType.SET_SAVED:
      return {
        ...state,
        saving: false,
        error: '',
        saved: action.payload,
      };
    case ActionType.SET_ERROR:
      return { ...state, saving: false, error: action.payload, saved: false };
    default:
      return state;
  }
};

interface IUseCreateSavedSearch {
  accessToken: string | undefined;
  saved?: boolean;
}

function useCreateSavedSearch(props: IUseCreateSavedSearch) {
  const { saved, accessToken } = props;
  const [state, dispatch] = useReducer(savedSearchReducer, {
    ...initialState,
    saved: saved || initialState.saved,
  });

  const { selectedCounty, coordinates, radius, countyTown } =
    useLocationContext();
  const { latitude, longitude } = coordinates;
  const county = selectedCounty?.value;

  const setLoading = () => {
    dispatch({
      type: ActionType.SET_SAVING,
    });
  };
  const setSaved = (saved: boolean) => {
    dispatch({
      type: ActionType.SET_SAVED,
      payload: saved,
    });
  };
  const setError = (error: string) => {
    dispatch({
      type: ActionType.SET_ERROR,
      payload: error,
    });
  };

  async function createSavedSearch(data: {
    name: string;
    terms: Array<string>;
    notifications: NotificationFrequency;
    userId: number;
    section: string;
    sort: string;
    keywordSearchTerm: string;
    filters: IGetAdsRequestFilter[];
    ranges: IGetAdsRequestRange[];
    makeModel: MakeModelType;
  }) {
    const {
      name,
      terms,
      notifications,
      userId,
      section,
      sort,
      keywordSearchTerm,
      filters,
      ranges,
      makeModel,
    } = data;
    // TODO: map filters for BE to toggle price name and remove string formattings
    const geoFilterCounty = county ? [county] : null;
    const locationData = mapGeoFilter({
      radius,
      latitude,
      longitude,
      county: geoFilterCounty,
      countyTown,
    });

    const mappedFilterValues = mapLegacyAreaFilterValues(
      mapFiltersRequest(filters),
      locationData?.geoFilter?.county,
      locationData?.geoFilter?.countyTown,
    );

    try {
      setLoading();
      await savedSearchApi.createSavedSearch(
        {
          name,
          description: formatSearchTerms(terms),
          frequency: notifications,
          search: {
            decayFunctions: [],
            makeModelFilters: mapMakeModelFilters(makeModel),
            filters: mappedFilterValues,
            ranges: mapRangesRequest(ranges),
            sort,
            terms: keywordSearchTerm,
            sections: [section],
            scoringRanges: [],
            scoringFilters: [],
            notFilters: [],
            paging: { pageSize: 30, from: 0 },
            geoFilter: mapGeoFilterRequest(locationData?.geoFilter),
          },
        },
        userId.toString(),
        500,
        formatAuthorizationHeader(accessToken),
        API_CLIENT_TIMEOUT,
      );
      setSaved(true);
    } catch (error) {
      rg4js('send', {
        error: new Error('Error handling createSavedSearch'),
        tags: [PAGE.SEARCH],
        customData: {
          message: error.message || 'client_error',
        },
      });
      setError('There was an error saving your search, please try again');
    }
  }

  return {
    createSavedSearch,
    setSaved,
    setLoading,
    setError,
    ...state,
  };
}

export { useCreateSavedSearch };
